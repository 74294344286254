import { useEffect, useState } from 'react';
import clsx from 'clsx';

import BackToTopIcon from '@public/icons/back-to-top.svg';

function BackToTop() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (!window) return;
    const toggleVisibility = () => {
      setIsVisible(window.scrollY > 500);
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const onClickButton = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  if (!isVisible) return null;
  return (
    <button
      className={clsx(
        'fixed right-[0.375rem] z-50',
        'md:bottom-[4.875rem] sm:bottom-[4.25rem]'
      )}
      aria-label="back to top button"
      onClick={onClickButton}
    >
      <BackToTopIcon />
    </button>
  );
}

export default BackToTop;
